<template>
  <div class="ele-body">
    <el-card shadow="never">
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="title" label="分润内容"  show-overflow-tooltip min-width="150"/>
          <el-table-column prop="price" label="分润金额（元）"  show-overflow-tooltip min-width="150"/>
          <el-table-column prop="set_area" label="分润地区" show-overflow-tooltip min-width="110"/>
          <el-table-column prop="create_time" label="分润时间"  show-overflow-tooltip min-width="150"/>
          <el-table-column prop="status" label="状态"  show-overflow-tooltip min-width="100"/>
          <el-table-column label="操作" width="200px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:profit:edit')">查看</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag title="分润详情" :visible.sync="showEdit" @closed="editForm={}" width="600px" :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" label-width="140px">
					<el-form-item label="广告商公司名：">
						<div>{{editForm.company_name}}</div>
					</el-form-item>
					<el-form-item label="投放地区：" >
						<div>{{editForm.set_area}}</div>
					</el-form-item>
						<el-form-item label="显示端：" >
							<div>{{editForm.send_app}}</div>
						</el-form-item>
					<el-form-item label="投放金额(元)：" >
						<div>{{editForm.fee}}元</div>
					</el-form-item>
					<el-form-item label="投放天数：" >
						<div>{{editForm.day_num}}天</div>
					</el-form-item>
					<el-form-item label="广告图片：" >
						<el-image style="width:100px;height:100px" :src="editForm.picture" fit="cover" :preview-src-list="[editForm.picture]">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</el-form-item>
					<el-form-item label="跳转链接：">
						<div>{{editForm.url}}</div>
					</el-form-item>
      </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SysAdminIaelist",
  data() {
    return {
      table: {url: '/adminiaelist/divided_list', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {},  // 表单验证规则
    }
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/adminiaelist/divided_detail?id=' + row.id).then(res => {
        this.editForm = res.data;
        if(this.editForm.send_app==1){this.editForm.send_app='咱县打车用户端'}
        else if(this.editForm.send_app==2){this.editForm.send_app='咱县打车司机端'}
        else if(this.editForm.send_app==3){this.editForm.send_app='咱县打车跑腿端'}
        else if(this.editForm.send_app==4){this.editForm.send_app='咱县打车商家端'}
        else if(this.editForm.send_app==5){this.editForm.send_app='可蚁点用户端'}
        else if(this.editForm.send_app==6){this.editForm.send_app='可蚁点司机端'}
        else if(this.editForm.send_app==7){this.editForm.send_app='可蚁点跑腿端'}
        else if(this.editForm.send_app==8){this.editForm.send_app='可蚁点商家端'}
        else if(this.editForm.send_app==9){this.editForm.send_app='全平台'}
        this.showEdit = true;
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
/deep/.el-dialog{
		margin-top: 100px !important;
	}
</style>